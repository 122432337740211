var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-primary",
      modifiers: {
        "modal-primary": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Ajustes Realizados ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-seconday",
      modifiers: {
        "modal-seconday": true
      }
    }],
    attrs: {
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Dados Salvos ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-success",
      modifiers: {
        "modal-success": true
      }
    }],
    attrs: {
      "variant": "outline-success"
    }
  }, [_vm._v(" Dados Editados ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-danger",
      modifiers: {
        "modal-danger": true
      }
    }],
    attrs: {
      "variant": "outline-danger"
    }
  }, [_vm._v(" Consulta Inicial Registrada ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-warning",
      modifiers: {
        "modal-warning": true
      }
    }],
    attrs: {
      "variant": "outline-warning"
    }
  }, [_vm._v(" Aplicação de Testes Cognitivos Registrada ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(0, 207, 232, 0.15)',
      expression: "'rgba(0, 207, 232, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-info",
      modifiers: {
        "modal-info": true
      }
    }],
    attrs: {
      "variant": "outline-info"
    }
  }, [_vm._v(" Pronto! Cadastro Realizado com Sucesso! ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(30, 30, 30, 0.15)',
      expression: "'rgba(30, 30, 30, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-dark",
      modifiers: {
        "modal-dark": true
      }
    }],
    attrs: {
      "variant": "outline-dark"
    }
  }, [_vm._v(" Paciente Adcionado ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-primary",
      "hide-footer": "",
      "hide-header": "",
      "ok-variant": "dark",
      "modal-class": "modal-dark",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "popup-img"
  }, [_c('img', {
    attrs: {
      "src": "assets/images/modal_icons/icon_pop-up_dados_salvos.png"
    }
  })]), _c('h5', {
    staticClass: "popup-title mt-2"
  }, [_vm._v("Ajustes Realizados")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Começar Partida! ")])], 1)]), _c('b-modal', {
    attrs: {
      "id": "modal-seconday",
      "hide-footer": "",
      "hide-header": "",
      "ok-variant": "dark",
      "modal-class": "modal-dark",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "popup-img"
  }, [_c('img', {
    attrs: {
      "src": "assets/images/modal_icons/icon_pop-up_dados_salvos.png"
    }
  })]), _c('h5', {
    staticClass: "popup-title mt-2"
  }, [_vm._v("Dados Salvos")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Continuar ")])], 1)]), _c('b-modal', {
    attrs: {
      "id": "modal-success",
      "hide-footer": "",
      "hide-header": "",
      "ok-variant": "dark",
      "modal-class": "modal-dark",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "popup-img"
  }, [_c('img', {
    attrs: {
      "src": "assets/images/modal_icons/icon_pop-up_paciente.png"
    }
  })]), _c('h5', {
    staticClass: "popup-title mt-2"
  }, [_vm._v("Dados Editados")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Voltar à Página Inicial ")])], 1)]), _c('b-modal', {
    attrs: {
      "id": "modal-danger",
      "hide-footer": "",
      "hide-header": "",
      "ok-variant": "dark",
      "modal-class": "modal-dark",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "popup-img"
  }, [_c('img', {
    attrs: {
      "src": "assets/images/modal_icons/icon_pop-up_consulta.png"
    }
  })]), _c('h5', {
    staticClass: "popup-title mt-2"
  }, [_vm._v("Consulta Inicial Registrada")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Continuar ")])], 1)]), _c('b-modal', {
    attrs: {
      "id": "modal-info",
      "hide-footer": "",
      "hide-header": "",
      "ok-variant": "dark",
      "modal-class": "modal-dark",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "popup-img"
  }, [_c('img', {
    attrs: {
      "src": "assets/images/modal_icons/icon_pop-up_check.png"
    }
  })]), _c('h5', {
    staticClass: "popup-title mt-2"
  }, [_vm._v("Pronto! Cadastro Realizado com Sucesso!")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Voltar à tela de Login ")])], 1)]), _c('b-modal', {
    attrs: {
      "id": "modal-warning",
      "hide-footer": "",
      "hide-header": "",
      "ok-variant": "dark",
      "modal-class": "modal-dark",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "popup-img"
  }, [_c('img', {
    attrs: {
      "src": "assets/images/modal_icons/icon_pop-up_consulta.png"
    }
  })]), _c('h5', {
    staticClass: "popup-title mt-2"
  }, [_vm._v("Aplicação de Testes Cognitivos Registrada")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Continuar ")])], 1)]), _c('b-modal', {
    attrs: {
      "id": "modal-dark",
      "hide-footer": "",
      "hide-header": "",
      "ok-variant": "dark",
      "modal-class": "modal-dark",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "popup-img"
  }, [_c('img', {
    attrs: {
      "src": "assets/images/modal_icons/icon_pop-up_paciente.png"
    }
  })]), _c('h5', {
    staticClass: "popup-title mt-2"
  }, [_vm._v("Paciente Adicionado")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Voltar à Página Inicial ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }